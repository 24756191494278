import React, { ChangeEvent, useEffect, useState } from 'react'
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import {  HiOutlineMinusSmall, HiOutlinePlusSmall } from "react-icons/hi2";
import { BsCart } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryList, fetchCategoryListtable } from '../../Redux/thunks/ReorderCategoryThunk';
import { RootState } from '../../Redux/store';
import { fetchPaymentBy } from '../../Redux/thunks/PaymentByThunk';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchReorderPost } from '../../Redux/thunks/ReorderPostThunk';

interface Product {
  id : string,
  category_name : string,
}
interface FormData {
    id : string,
    currency :string,
    cc_Trans_No: string
}
interface CartItem {
    id: string;
    count: number;
    price: number;
  }
interface productData {
    product_name :string,
    product_code : string
}

function ReorderPage() {
    const { categoryData ,productData  } = useSelector((state: RootState) => state.categorylist);
    const {  paymentData } = useSelector((state: RootState) => state.paymentby);
    
    const [formData , setFormData] = useState<FormData>({
        id: '',
        currency : "",
        cc_Trans_No: ""
    })

 // State for the cart and total price
const [cart, setCart] = useState<{ [productId: string]: CartItem }>(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : {};
  });
  
  const [totalPrice, setTotalPrice] = useState<number>(() => {
    const savedTotal = localStorage.getItem('totalPrice');
    return savedTotal ? JSON.parse(savedTotal) : 0;
  });

    const dispatch = useDispatch<any>();
    useEffect(()=>{
        dispatch(fetchCategoryList())
    },[dispatch])

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(productData);
  
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLowerCase();
      setSearchTerm(value);
    };
  
    useEffect(() => {
      if (searchTerm === '') {
        setFilteredProducts(productData);
      } else {
        const filtered = productData.filter((product :productData) =>
          product.product_code.toLowerCase().includes(searchTerm) || 
          product.product_name.toLowerCase().includes(searchTerm)
        );
        setFilteredProducts(filtered);
      }
    }, [searchTerm, productData]);

        const onHandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedId = e.target.value;
            
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: selectedId 
            }));
    
            if (selectedId) {
                dispatch(fetchCategoryListtable(parseInt(selectedId)));
            }
        };
        const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            
            setFormData((prev) => ({
              ...prev,
              [name]: value,  
            }));

            if (value) {
                dispatch(fetchPaymentBy(value));  
            }
        };
       
        //   ----count--
const updateCart = (productId: string, price: number, delta: number) => {
    
    setCart((prevCart) => {
      const existingItem = prevCart[productId] || { count: 0, price: 0 };
      
      const newCount = existingItem.count + delta;
      
      if (newCount < 0) return prevCart;
  
      const newItemPrice = price * newCount;
  
      const updatedCart = {
        ...prevCart,
        [productId]: {
          id: productId, 
          count: newCount,
          price: newItemPrice,
        },
      };
  
      const newTotalPrice = Object.values(updatedCart).reduce((total, item) => total + item.price, 0);
      setTotalPrice(newTotalPrice); 
      return updatedCart;
    });
  };
  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
  }, [cart, totalPrice]);
   
  const validationErrors = () => {
    const newErrors: any = {};
    if (!formData.currency) {
        newErrors.currency = "Currency field is required";
    }
    if (formData.currency && totalPrice > paymentData.balance) {
        toast.error("Total price cannot be less than the balance!");
    }
    return newErrors;
  }
  const [error,setError]= useState<any>();

  const products_data = Object.values(cart)
  .filter(item => item.count > 0) 
  .map(item => ({
    id: item.id,
    count: item.count
  }));
  
  const handleSubmit =async (e: React.FormEvent<HTMLFormElement>)=> {
    e.preventDefault();
    const errors = validationErrors();
  const data = {
    "category_name" : formData.id,
    "products_data" : products_data,
    "payment_type" : formData.currency,
    "cc_Trans_No" : formData.cc_Trans_No || null
  }   
  if (products_data.length === 0) {
    toast.error( "Please select at least one product.")
  }else{
      if (Object.keys(errors).length === 0 ) {
             const res =  await dispatch(fetchReorderPost(data))
             if(res.data.success === true){
                 toast(res.data.message)
                 localStorage.removeItem('cart')
                 localStorage.removeItem('totalPrice')   
                 setFormData({
                  id: '',
                  currency : "",
                  cc_Trans_No: ""
              })
              }else{
                 toast.error(res.data.message)
             }
      } else {
        setError(errors)
      }
  }
  };

  return (
    <>
      <Layout>
       <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border z-10">
        <div className="container">
                        <div className="relative">
                            <Link to="/dashboard" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Reorder Page</h3>
                        </div>
        </div>
       </header>
       <ToastContainer/>
        <section className="py-20">
                    <div className="container">
                 <form onSubmit={handleSubmit}>
                    <div className="border rounded-lg p-5 border-[#DCDCE9] bg-white mb-7">
                    <div className="mb-3">
                    <label className="text-[#1e293b] text-[14px]">Category</label>
                    <select
                        name="id"
                        className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                        value={formData.id}
                        onChange={onHandleChange}
                    >
                        <option value="">Select Category</option>
                        {categoryData && categoryData.map((item: Product) => (
                        <option key={item.id} value={item.id}>
                            {item.category_name}
                        </option>
                        ))}
                    </select>
                    </div>
                    <div className="mb-3">
                    <label className="text-[#1e293b] text-[14px]">Search  By(Product Code/Number)</label>
                    <input
                        type="text"
                        name="search"
                        placeholder="Enter Product Code/Number)"
                        className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                        onChange={handleSearch}
                    />
                    </div>
                    <div className="relative overflow-x-auto mt-5 border rounded-md mb-4">
                    <table  id="example" className="display table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th className="px-6 py-3">
                                        Code
                                </th>
                                <th className="px-6 py-3 text-center">
                                Name
                                </th>
                                <th className="px-6 py-3">
                            Member Price
                                </th>
                                <th className="px-6 py-3">
                                  LP
                                </th>
                                <th className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                    {filteredProducts && filteredProducts.length > 0 ? (
                                filteredProducts.map((item: any, index: number) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#efeff1]"}>
                                                      
                                <td className="px-6 py-3">
                                    {item.product_code}
                                </td>
                                <td className="px-6 py-3 text-center">
                                       {item.product_name}
                                </td>
                                <td className="px-6 py-3">
                                    {item.product_associate_price}
                                </td>
                                <td className="px-6 py-3">
                                     {item.product_lp}
                                </td>
                                <td className="px-6 py-3">
                                <div className="flex gap-2 items-center">
                                <HiOutlineMinusSmall
                                  onClick={() => updateCart(item.id, item.product_associate_price, -1)}
                                  className="cursor-pointer"
                                />
                                <BsCart /> {cart[item.id]?.count || 0}
                                <HiOutlinePlusSmall
                                  onClick={() => updateCart(item.id, item.product_associate_price, 1)}
                                  className="cursor-pointer"
                                />
                              </div>
                                </td>
                            </tr>
                           ))
                        ) : (
                    <tr>
                    <td colSpan={6} className="px-6 py-2 text-center">No data available</td>
                    </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    <div className="border rounded-lg p-5 border-[#DCDCE9] bg-white">
                    <div className="mb-3">
                    <label className="text-[#1e293b] text-[14px]">Payment By</label>
                    <select
                        name="currency"
                        className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                        value={formData.currency}
                        onChange={handleChange}
                    >
                        <option value="">Select</option>
                        <option value="creditcard">CREDIT CARD</option>
                        <option value="RC">PP</option>
                        <option value="PP2">PP2</option>
                        <option value="SP">SP</option>
                    </select>
                        {formData.currency === "creditcard" && (
                            <input
                                type="text"
                                name="cc_Trans_No"
                                className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                                placeholder="Enter Credit Card Number"
                                value={formData.cc_Trans_No || ''}
                                onChange={handleChange}
                            />
                        )}
                        <h5 className='mt-3'>{paymentData && paymentData.balance}</h5>   
                        {error && <p className='text-red-500 text-xs mt-2'>{error.currency}</p>}
                    </div>
                     <div className="mb-3 text-end">
                     <button className='py-2 px-3 rounded-md bg-[#178285] text-white text-sm' type='submit'>Submit</button>
                    </div>
                    </div>
                 </form>
                    </div>
        </section>
      </Layout>
    </>
  )
}

export default ReorderPage
