import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductList } from '../../Redux/thunks/productListReducer';
import { useLocation } from 'react-router-dom';
import { fetchUserDetailData } from '../../Redux/thunks/UserDetailsThunk';
import { fetchAddMember } from '../../Redux/thunks/AddMemberThunk';
import { fetchNumber } from '../../Redux/thunks/mobileNumThunk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Product {
    combo_product_name: string,
    combo_product_lp: number,
    combo_product_retail_price: string,
    id: number
}
interface FormData {
    sponsor: string,
    placement: string,
    matrix_side: string,
    account_type: number,
    f_name: string,
    l_name: string,
    e_mail: string,
    mobile: string,
    sponsor_type: number,
    package_id: string,
    country: number,
    isFieldsDisabled?: boolean;
}

const AddMemberForm = () => {
    const location = useLocation();
    const { col, upline_id } = location.state || {};
    const dispatch = useDispatch<any>();
    const { productListData } = useSelector((state: RootState) => state.product);
     const { UserDetailData } = useSelector((state: RootState) => state.userDetail);
    const [formData, setFormData] = useState<FormData>({
        sponsor: upline_id || "",
        placement: upline_id || "",
        matrix_side: col || "",
        account_type: 0,
        f_name: "",
        l_name: "",
        e_mail: "",
        mobile: "",
        sponsor_type: 0,
        package_id: "",
        country: 194
    });

    const userdata ={
        action : "checkuserdetail",
        userid : upline_id
    }
    
    useEffect(() => {
        dispatch(fetchProductList());
        dispatch(fetchUserDetailData(userdata)); 
    }, [dispatch]);


    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
    
        if (type === "radio" && name === "account_type") {
            const newAccountType = Number(value);
    
            setFormData(prev => {
                const updatedFormData = { ...prev, [name]: newAccountType };
    
                if (newAccountType === 1) {
                    return {
                        ...updatedFormData,
                        f_name: UserDetailData.f_name,
                        l_name: UserDetailData.l_name,
                        e_mail: UserDetailData.email,
                        mobile: UserDetailData.mobile,
                        isFieldsDisabled: true 
                    };
                } else {
                    return {
                        ...updatedFormData,
                        f_name: '',
                        l_name: '',
                        e_mail: '',
                        mobile: '',
                        isFieldsDisabled: false 
                    };
                }
            });
        } else if (type === "radio") {
            setFormData(prev => ({ ...prev, [name]: Number(value) }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };
    
    
    const [errors, setErrors] = useState<any>({});

    const validateForm = () => {
        const newErrors: any = {};
        if(formData.isFieldsDisabled){
        if (!formData.sponsor) newErrors.sponsor = "Sponsor is required";
            if (!formData.placement) newErrors.placement = "Placement is required";
            if (!formData.matrix_side) newErrors.matrix_side = "Matrix Side is required";
            if (!formData.package_id) newErrors.package_id = "Package is required";
                if (!formData.account_type && formData.account_type !== 0) newErrors.account_type = "Account Type is required";
            if (!formData.sponsor_type && formData.sponsor_type !== 0) newErrors.sponsor_type = "Sponsor Type is required";
        }else{
             if (!formData.f_name) newErrors.f_name = "First Name is required";
        if (!formData.l_name) newErrors.l_name = "Last Name is required";
        if (!formData.e_mail || !/\S+@\S+\.\S+/.test(formData.e_mail)) newErrors.e_mail = "Valid Email is required";
        if (!formData.mobile || formData.mobile.length < 8) newErrors.mobile = "Mobile number must be at least 8 digits long";
        if (!formData.package_id) newErrors.package_id = "Package is required";
        if (!formData.account_type && formData.account_type !== 0) newErrors.account_type = "Account Type is required";
        if (!formData.sponsor_type && formData.sponsor_type !== 0) newErrors.sponsor_type = "Sponsor Type is required";
        }
        return newErrors;
    };

    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            if(formData.isFieldsDisabled){
                dispatch(fetchAddMember(formData)); 
                toast("Member added Successfully!")
            setFormData({
            sponsor: "",
            placement:  "",
            matrix_side:  "",
            account_type: 0,
            f_name: "",
            l_name: "",
            e_mail: "",
            mobile: "",
            sponsor_type: 0,
            package_id: "",
            country: 194
            })
            navigate('/placement-tree')
          }  else if (formData) {
                const mobileDetail ={
                    action : "checkuniquemobile",
                    phone_no : formData.mobile,
                    account : formData.account_type,
                    sponsor : upline_id
                } 
                const response = await  dispatch(fetchNumber(mobileDetail));
                const numberData = response.data;
                if (numberData.success) {
                     dispatch(fetchAddMember(formData)); 
                     toast("Member added Successfully!")
                   setFormData({
                    sponsor: upline_id || 0,
                    placement:   upline_id || 0 ,
                    matrix_side:  col || 0,
                    account_type: 0,
                    f_name: "",
                    l_name: "",
                    e_mail: "",
                    mobile: "",
                    sponsor_type: 0,
                    package_id: "",
                    country: 194
                   })
                   navigate('/placement-tree')
                } else {
                    toast("Fetch number response is not true, form submission aborted")
                }
            }
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            <Layout>
                
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/placement-tree" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Add Member</h3>
                        </div>
                    </div>
                </header>
                <ToastContainer />
                <section className='pt-20 pb-24'>
                    <div className="container">
                        <div className="p-[20px] bg-white rounded-md">
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Sponsor</label>
                                    <input
                                        type="text"
                                        name="sponsor"
                                        placeholder='Sponsor'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.sponsor}
                                        onChange={handleChange}
                                    />
                                    {errors.sponsor && <p className='text-red-500 text-xs'>{errors.sponsor}</p>}
                                </div>
                                {/* Repeat similar blocks for other fields */}
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Placement</label>
                                    <input
                                        type="text"
                                        name="placement"
                                        placeholder='Placement'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.placement}
                                        onChange={handleChange}
                                    />
                                    {errors.placement && <p className='text-red-500 text-xs'>{errors.placement}</p>}
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Matrix Side</label>
                                    <select
                                        name="matrix_side"
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.matrix_side}
                                        onChange={handleChange}
                                    >
                                        <option value="">Auto</option>
                                        <option value="R">Right</option>
                                        <option value="L">Left</option>
                                    </select>
                                    {errors.matrix_side && <p className='text-red-500 text-xs'>{errors.matrix_side}</p>}
                                </div>
                                <div className="mb-3">
                                    <label  className='text-[#1e293b] text-[14px] mb-1'>Account Type</label>
                                    <div className='flex justify-around'>
                                        <div className="flex items-center">
                                            <input
                                                id="main-account"
                                                type="radio"
                                                name="account_type"
                                                value="0"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                checked={formData.account_type === 0}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="main-account" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Main Account</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="sub-account"
                                                type="radio"
                                                name="account_type"
                                                value="1"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                checked={formData.account_type === 1}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="sub-account" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sub Account</label>
                                        </div>
                                    </div>
                                    {errors.account_type && <p className='text-red-500 text-xs'>{errors.account_type}</p>}
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>First Name</label>
                                    <input
                                        type="text"
                                        name="f_name"
                                        placeholder='First Name'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.f_name}
                                        onChange={handleChange}
                                        disabled={formData.isFieldsDisabled}
                                    />
                                    {
                                        formData.isFieldsDisabled ? "" : (   errors.f_name && <p className='text-red-500 text-xs'>{errors.f_name}</p>)
                                    }
                                 
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Last Name</label>
                                    <input
                                        type="text"
                                        name="l_name"
                                        placeholder='Last Name'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.l_name}
                                        onChange={handleChange}
                                        disabled={formData.isFieldsDisabled}
                                    />
                                    {
                                        formData.isFieldsDisabled ? "" : (   errors.l_name && <p className='text-red-500 text-xs'>{errors.l_name}</p>)
                                    }
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Email</label>
                                    <input
                                        type="email"
                                        name="e_mail"
                                        placeholder='Email'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.e_mail}
                                        onChange={handleChange}
                                        disabled={formData.isFieldsDisabled}
                                    />
                                    {
                                        formData.isFieldsDisabled ? "" : (   errors.e_mail && <p className='text-red-500 text-xs'>{errors.e_mail}</p>)
                                    }
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Mobile</label>
                                    <input
                                        type="number"
                                        name="mobile"
                                        placeholder='Mobile'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        disabled={formData.isFieldsDisabled}
                                    />
                                    {
                                        formData.isFieldsDisabled ? "" : (   errors.mobile && <p className='text-red-500 text-xs'>{errors.mobile}</p>)
                                    }
                                </div>
                                <div className='mb-3'>
                                    <input
                                        type="hidden"
                                        name="country"
                                        placeholder='Country'
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.country}
                                        onChange={handleChange}
                                    />
                                    {errors.country && <p className='text-red-500 text-xs'>{errors.country}</p>}
                                </div>
                                <div className="mb-3">
                                    <label className='text-[#1e293b] text-[14px] mb-1'>Sponsor Type</label>
                                    <div className='flex justify-around'>
                                        <div className="flex items-center">
                                            <input
                                                id="main-account"
                                                type="radio"
                                                name="sponsor_type"
                                                value="0"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                checked={formData.sponsor_type === 0}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="main-account" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Company Sponsor</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="sub-account"
                                                type="radio"
                                                name="sponsor_type"
                                                value="1"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                checked={formData.sponsor_type === 1}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="sub-account" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Normal</label>
                                        </div>
                                    </div>
                                    {errors.sponsor_type && <p className='text-red-500 text-xs'>{errors.sponsor_type}</p>}
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#1e293b] text-[14px]'>Package</label>
                                    <select
                                        name="package_id"
                                        className='mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md'
                                        value={formData.package_id}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Package</option>
                                        {productListData.map((product: Product) => (
                                            <option key={product.id} value={product.id}>
                                                {product.combo_product_name} - {product.combo_product_lp}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.package_id && <p className='text-red-500 text-xs'>{errors.package_id}</p>}
                                </div>
                                <div className='text-end'>
                                        <button type='submit' className='py-2 px-3 rounded-md bg-[#178285] text-white text-sm '>Submit</button>
                                    </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default AddMemberForm;
