import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../Components/Layout'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { fetchInvoiceList } from '../../Redux/thunks/InvoiceListThunk';
import { IoPrint } from "react-icons/io5";
import "datatables.net";
import DataTable from 'datatables.net-dt';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { fetchInvoicePdf } from '../../Redux/thunks/InvoicePdfThunk';
import { ToastContainer } from 'react-toastify';

function Invoice() {
    const { invoiceData} = useSelector((state: RootState) => state.invoicelist);
    const tableRef = useRef(null);
 
    useEffect(() => {
        if (tableRef.current) {
            const dataTable = new DataTable(tableRef.current,{
                searching: false,
            });
            return () => {
                if (dataTable) {
                    dataTable.destroy(true);
                }
            };
        }
    }, []);

    // --search input
    const [searchDate, setSearchDate] = useState("");

  // Function to handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDate(e.target.value);
  };

const filteredData = invoiceData?.filter((item: any) => {
    const query = searchDate.toLowerCase();
    return (
      item.invoice_no.toLowerCase().includes(query) ||
      item.order_date.toLowerCase().includes(query) ||
      item.product.product_name.toLowerCase().includes(query) ||
      item.product.product_associate_price.toString().includes(query) ||
      item.payment_type.toLowerCase().includes(query)
    );
  });
    const dispatch = useDispatch<any>();

    const initialReport = {
        from_year: "2022",
        from_month: "01",
        to_year: "2024",
        to_month: "09"
    };

    useEffect(() => {        
        dispatch(fetchInvoiceList(initialReport));
    }, [dispatch]);

    const formSubmit = (values: any) => {
        dispatch(fetchInvoiceList(values));
    };

    const formik = useFormik({
        initialValues: initialReport,
        onSubmit: formSubmit,
    }) as any;
    
    // ----pdf download 
    const handleDownloadPdf =async (id: number) => {
        try {
        const pdfData = await   dispatch(fetchInvoicePdf(id));
        const container = document.createElement('div');
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.width = "200mm";
    container.style.padding = "15px";

    const { 
        invoice_no, 
        invoice_date, 
        name, 
        country, 
        mobile, 
        products, 
        payment_type,
        userid,
        sponsorid,
        total_amount 
    } = pdfData;
    const totalQuantity = products.reduce((acc : any, product : any) => acc + product.quantity, 0);
    
        const productRows = products.map((product : any, index :number) => {
            return `
                <tr>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${index + 1}</td>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${product.product.product_code}</td>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${product.product.product_name}</td>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${product.quantity}</td>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${parseFloat(product.amount).toFixed(2)}</td>
                    <td class='px-1 pt-0 border-t border-solid border-black text-[9px] pb-3'>${parseFloat(product.total_amount).toFixed(2)}</td>
                </tr>
            `;
        }).join('');

        const billContent = document.createElement('div');
        billContent.id = "bill";
        billContent.innerHTML = `
               <div class="container mx-auto">
                    <h3 class='text-end uppercase text-[10px]'>TAX INVOICE/OFFICIAL RECEIPT </h3>
                    <div class="flex justify-between mb-2">
                 <div>
                    <img src="images/pdfimage.jpg" alt="" class='w-28' />
                 </div>
                 <div class='flex px-1 '>
                   <ul>
                    <li class='mb-1 text-end text-[9px]'>Co. Registration No.: </li>
                    <li class='mb-1 text-end text-[9px]'>Invoice / Receipt No.: </li>
                    <li class='mb-1 text-end text-[9px]'>Invoice / Receipt Date: </li>
                   </ul>
                   <ul>
                    <li class='mb-1 text-end text-[9px]'> T11LL0417L</li>
                    <li class='mb-1 text-end text-[9px]'> ${invoice_no} </li>
                    <li class='mb-1 text-end text-[9px]'> ${invoice_date}</li>
                   </ul>
                 </div>
                    </div>
                 <div class=" mb-2">
                        <table class='w-full border border-solid border-black '>
                        <h3 class='font-semibold px-1 pt-0 text-[10px] pb-3'>Invoiced To</h3>
                            <tbody>
                                <tr>
                                    <td class='px-1 pt-0'>
                                    <div class="flex border-right border-solid border-black px-1 ">
                            <ul class='pt-0 pb-3'>
                                <li class='text-[9px]'>Name:</li>
                                <li class='text-[9px]'>Address:</li>
                                
                                <li class='text-[9px]'>Contact:</li>
                            </ul>
                            <ul class='pt-0 pb-3'>
                                <li class='text-[9px]'>${name} </li>
                                <li class='text-[9px]'>${country}
                                </li>
                                <li class='text-[9px]'> ${mobile}
                                </li>
                            </ul>
                        </div>
                                    </td>
                                    <td>
                                    <div class="flex px-1 ">
                            <ul>
                                <li class='text-[9px]'>ID Code:</li>
                                <li class='text-[9px]'>Sponsor ID: </li>
                            </ul>
                            <ul>
                                <li class='text-[9px]'> ${userid} </li>
                                <li class='text-[9px]'> ${sponsorid}</li>
                            </ul>
                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                 </div>
                 <table class='border border-solid border-black mb-2 w-full'>
                    <thead>
                        <tr>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'>S/N</th>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'>PDT CODE</th>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'>PRODUCT DESCRIPTION</th>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'>QTY</th>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'> UNIT $</th>
                            <th class='px-1 pt-0 text-start text-[10px] pb-3'>TOTAL $</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                          ${productRows}
                    </tbody>
                 </table>
               <table class='border border-solid border-black mb-2 w-full h-32 align-top'>
                <tbody>
                <tr >
                            <td class='align-top px-1 pt-0 text-[10px] pb-3'>Total Quantity:</td>
                            <td class='align-top px-1 pt-0 text-[9px]  pb-3'> ${totalQuantity} </td>
                            <td class='align-top px-1 pt-0 text-[10px]  pb-3'> GRAND TOTAL $: </td>
                            <td class='align-top px-1 pt-0 text-[9px]  pb-3'>${total_amount}</td>
                        </tr>
                </tbody>
               </table>
               <div class="mb-30">
                <h3 class='font-semibold text-[10px]'>PAYMENT DETAILS </h3>
                <table class=' mb-2 w-full'>
                    <tbody>
                        <tr>
                            <td class='font-semibold px-1 pt-0 text-[9px]  pb-3'>Amount Paid $: </td>
                            <td class='text-[9px]  pb-3'> ${total_amount} </td>
                            <td class='font-semibold px-1 pt-0 text-[9px]  pb-3'>Balance Payment $0 </td>
                        </tr>
                        <tr>
                            <td class='font-semibold px-1 pt-0 text-[9px]  pb-3'>Payment Mode: </td>
                            <td class='px-1 pt-0 text-[9px]  pb-3'>${payment_type}</td>
                        </tr>
                    </tbody>
                </table>
                <table class='border border-solid border-black w-full'>
                    <tbody>
                        <tr>
                            <td rowSpan="2" class='border border-solid border-black align-top px-1 pt-0 text-lg w-[70%]  pb-3'>Thank you for your order. Sales are governed by the Company LLP Policies and Procedures</td>
                            <td colSpan="2" class='border border-solid border-black px-1 pt-0 text-base pb-3'>
                            I confirmed this order is accurate and I accept the accompanying terms and conditions including overleaf. </td>
                            
                        </tr>
                        <tr>
                            <td class='border border-solid border-black px-1 pt-0 font-semibold text-[10px]  pb-3'>Signature</td>
                            <td class='border border-solid border-black px-1 pt-0 font-semibold text-[10px]  pb-3'> Date </td>
                        </tr>
                    </tbody>
                </table>
                <p class='text-[9px]'>Computer generated receipt, no signature required.</p>
               </div>
                </div>
        `;
        
        container.appendChild(billContent);
        
        document.body.appendChild(container);
        
        html2canvas(container)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
            pdf.save('invoice.pdf');
            
            document.body.removeChild(container);
          });
        } catch (error) {
            console.log(error);
        }
     
      };
  return (
    <>
             <Layout>
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/dashboard" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Invoice List</h3>
                        </div>
                    </div>
                </header>
                <ToastContainer/>
                <section className="pt-20  ">
                    <div className="container">
                    <div className="bg-white p-4 border rounded-md">
                        <form onSubmit={ formik.handleSubmit }>
                           <div className="mb-3">
                           <label className="text-xs text-[#a8a1a7]">From</label>
                            <div className="flex gap-5 md:gap-20">
                            <select  {...formik.getFieldProps('from_year')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">  
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            <span>-</span>
                            <select {...formik.getFieldProps('from_month')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                            </div>
                           </div>
                           <div className="mb-3">
                           <label className="text-xs text-[#a8a1a7]">Until</label>
                            <div className="flex gap-20">
                            <select {...formik.getFieldProps('to_year')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                <option value="2024">2024</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                            <span>-</span>
                            <select  {...formik.getFieldProps('to_month')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                            </div>
                           </div>
                           <div className="mt-4">
                            <button type="submit" className="py-3 w-full bg-[#178285] text-xs text-white rounded-md hover:bg-[#227275]">View</button>
                           </div>
                        </form>
            <div className="relative overflow-x-auto mt-5 border rounded-md">
                <div className="flex justify-center tablet:justify-end tablet:mb-[-50px] items-center gap-2 z-20 relative">
                    <label>Search :</label>
            <input
        type="text"
        placeholder="Search"
        value={searchDate}
        onChange={handleSearchChange}
        className=" py-2 px-2 border rounded mt-2 me-2 text-xs placeholder:text-sm"
      />
                </div>
                <table ref={tableRef}  style={{ width: "100%" }}   className="display table-auto w-full text-sm text-left rtl:text-right text-gray-500" >
          
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-6 py-3">
                                Invoice
                            </th>
                            <th className="px-6 py-3">
                            Date
                            </th>
                            <th className="px-6 py-3">
                            Description
                            </th>
                            <th className="px-6 py-3">
                                Price
                            </th>
                            <th className="px-6 py-3">
                              Transaction
                            </th>
                            <th  className="px-6 py-3">action</th>
                        </tr>
                    </thead>
                    <tbody>
                   {filteredData   && filteredData.length > 0 ? (
                          filteredData.map((item: any, index: number) => (
                           <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#efeff1]"}>
                           <td className="px-6 py-2">{item.invoice_no}</td>
                        <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                           {item.order_date}
                             </td>
                            <td className="px-6 py-2">{item.product.product_name}</td>
                             <td className="px-6 py-2">{item.product.product_associate_price}</td>
                             <td className="px-6 py-2">{item.payment_type}</td>
                         <td className="px-6 py-2"><IoPrint className='text-xl cursor-pointer' onClick={()=>handleDownloadPdf(item.id)}  /></td>
                           </tr>
                     ))) : "" }
                    </tbody>
                </table>
             </div> 
                    </div>
                    </div>
                </section>
            </Layout>
    </>
  )
}

export default Invoice
